body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 14px;
}

#root, html, body {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  background-color:#fff;
  width: 10px;
  height: 10px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color:#fff;
  width : 10px;
  height: 10px;
}
::-webkit-scrollbar-track:hover {
  background-color: #fff;
  width : 10px;
  height: 10px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius: 1em;
  width : 10px;
  height: 10px;
  min-height: 50px;
  border: 1px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:1px solid #f4f4f4;
  width : 10px;
  height: 10px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {display:none}
